import { createSlice } from "@reduxjs/toolkit";

export const cartSlice = createSlice({
  name: "cart",
  initialState: 0,
  reducers: {
    cartLength: (state, action) => {
      const cartItem = JSON.parse(localStorage.getItem("cart"));
      const sumWithInitial = cartItem && cartItem.reduce((accumulator, currentValue) => accumulator + currentValue.qty, 0);

      return (state = sumWithInitial);
    }
  }
});

export const { cartLength } = cartSlice.actions;
export default cartSlice.reducer;
