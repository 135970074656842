// export const BASE_URL = "https://devadmin.excelwater.ca/";
export const BASE_URL = window.location.hostname.includes("excelwater.ca") ? "https://admin.excelwater.ca/" : "https://devadmin.excelwater.ca/";
export const API_URL = BASE_URL + "api/";

export const STRIPE_PUB_KEY = "pk_test_51Q0mQYAJpvPRwM1Ngos9VvfuoKEcZzTxxBxJqgAHXGgqlt5XYwUSu2u3lRCkZmtu5UVyPJlAkRApmZIB3su6WbsL00J7Kd9zOv";

export const RE_CAPTCHA_SITE_KEY = "6LcKiFgqAAAAANV02lOjPdTqPTL0_MLpXBRxncGu";

//header
export const API_GET_DATA = "get-data";

//login
export const API_LOGIN = "login";
export const API_LOGOUT = "logout";
export const API_REGISTER = "registration";
export const API_GET_PWD_RESET_LINK = "forgot-password";
export const API_RESET_PASSWORD = "reset-password";
export const API_VERIFY_TOKEN = "verify-token";

//account-details
export const API_GET_PROFILE = "get-profile";
export const API_UPDATE_PROFILE = "update-profile";
export const API_CHANGE_PASSWORD = "change-password";
export const API_ADD_ADDRESS = "add-address";
export const API_LIST_ADDRESS = "address-list";
export const API_UPDATE_ADDRESS = "update-address";
export const API_DELETE_ADDRESS = "delete-address";
export const API_GET_ADDRESS = "get-address";
export const API_GIFT_CARD_ORDER_LIST = "gift-card-order-list";
export const API_GIFT_CARD_ORDER_DETAILS = "gift-card-order-details";
export const API_LIST_PRODUCT = "product-list";
export const API_DETAIL_PRODUCT = "product-details";

export const API_GET_SHOPPING_CART_PRODUCTS = "get-shopping-cart-products";

//checkout
export const API_LIST_ORDER = "order-list";
export const API_DETAILS_ORDER = "order-details";
export const API_PLACE_ORDER = "place-order";
export const API_PRINT_INVOICE = "print-invoice";

export const API_GET_FILTER_DATA = "get-filter-data";
export const API_PRODUCT_REVIEW = "product-review";
export const API_GET_PRODUCT_REVIEW = "get-products-reviews";
export const API_CONTACT_US = "contact-us";
export const API_HOME = "home";
export const API_GET_GIFT_CARD_AMOUNTS = "get-gift-card-amounts";
export const API_GIFT_CARD_PLACE_ORDER = "gift-card-place-order";
export const API_APPLY_GIFT_CARD = "apply-gift-card";
export const API_APPLY_GIFT_CARD_OTP = "apply-gift-card-otp";
export const API_PAYMENT_SUCCESS = "payment-success";
export const API_PAYMENT_FAIL = "payment-fail";

export const API_PARTNER_WITH_US = "partner-with-us";
