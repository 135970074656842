import React, { Suspense, lazy, useEffect } from "react";
import Loader from "./components/lib/Loader";
import { preload } from "swr";
import { API_GET_DATA, API_HOME } from "./services/ApiEndPoint";
import { fetcher } from "./services/ApiServices";

const Routes = lazy(() => {
  return new Promise((resolve) => {
    preload(API_GET_DATA, fetcher);
    preload(API_HOME, fetcher);
    setTimeout(() => resolve(import("./Routes")), 100);
  });
});

const App = () => {
  const emptyCache = () => {
    if ("caches" in window) {
      caches.keys().then((names) => {
        names.forEach((name) => {
          caches.delete(name);
        });
      });
    }
  };

  useEffect(() => {
    emptyCache();
  }, []);

  // useEffect(() => {
  //   // const clearCacheFirstTime = () => {
  //   //   const cacheCleared = localStorage.getItem("cacheCleared");
  //   //   if (!cacheCleared && "caches" in window) {
  //   //     caches.keys().then((names) => {
  //   //       names.forEach((name) => {
  //   //         caches.delete(name);
  //   //       });
  //   //     });

  //   //     window.location.reload(true);
  //   //     localStorage.clear();
  //   //     localStorage.setItem("cacheCleared", true); // Set flag after clearing cache
  //   //   }
  //   // };

  //   // clearCacheFirstTime();
  //   console.log(window.caches.CacheStorage);

  //   // // Set up event listener to reset cacheCleared when window is closed
  //   // const resetCacheFlagOnClose = () => {
  //   //   localStorage.setItem("cacheCleared", false);
  //   // };

  //   // window.addEventListener("beforeunload", resetCacheFlagOnClose);

  //   // // Clean up the event listener when the component is unmounted
  //   // return () => {
  //   //   window.removeEventListener("beforeunload", resetCacheFlagOnClose);
  //   // };
  // }, []); // Empty dependency array to run only on first mount

  return (
    <Suspense fallback={<Loader />}>
      <Routes />
    </Suspense>
  );
};

export default App;
